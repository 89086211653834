body {
    background: $primary-variant;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.container {
    padding: 0;
    display: inline-block;
    justify-content: space-between;
    width: 100%;

    .container__div {
        display: block;
        background: $primary;
        margin: 10px;
        height: 220px;
        border-radius: 10px;
        padding: 5px;
        box-shadow: 5px 5px 5px 1px gray;

        img {
            max-width: 80%;
            height: 150px;
            align-items: center;
            display: block;
            margin: auto;
            border-radius: 10px;
            cursor: pointer;
            object-fit: contain;
        }

        h1 {
            text-align: center;
            font-size: 20px;
            color: #fff;
        }
    }
}

.dialog-container {
    display: block;

    p {
        text-align: justify;
    }
    .dialog-container__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .dialog-container__images {
        margin: auto;
        align-items: center;
        width: 100%;

        .sliderimg {
            width: 50%;
            object-fit: contain;
            margin: auto;
            border-radius: 5px;
        }
    }
}

.alice-carousel ul li img {
    width: 100%;
    object-fit: cover;
    align-items: center;
    display: block;
    margin: auto;
    max-height: 250px;
}

@media (min-width: 768px) {
    .container {
        padding-top: 50px;
        display: inline-block;
        text-align: center;
        .container__div {
            width: 180px;
            height: 220px;
            vertical-align: top;
            display: inline-block;
        }
    }
}
