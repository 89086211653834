.nav__responsive-ul {
    display: none;
}
.nav {
    background: $accent-variant;
    width: 100vw;
    position: fixed;
    z-index: 999;

    .nav__li {
        display: inline-block;
        padding: 12px;
        color: $white;
        height: 100%;
        vertical-align: middle;

        .nav__logo {
            max-width: 30px;
            margin-left: 0;
        }
    }

    .nav__li:hover {
        color: $accent-yellow;
    }

    .nav__li:hover > a {
        color: $accent-yellow;
    }

    .nav__li a {
        color: $white;
        text-decoration: none;
        list-style-type: none;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        padding: 5px;
    }

    .nav__responsive-button-container {
        position: sticky;
        width: 100%;
        background: $accent-variant;
        font-size: 25px;
        margin-top: -64px;
        z-index: 100;
    }

    .nav__responsive-button {
        padding-top: 6px;
        padding-left: 15px;
        text-align: left;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .nav {
        height: 37px;
        width: 100%;
        background: $accent-variant;

        .nav__ul {
            display: none;
        }

        .nav__responsive-button {
            width: 10%;
        }

        .nav__li-container {
            position: relative;
            left: -1000px;
            height: calc(100vh);
            width: 60%;
            background: $accent-variant;
            transition: all 0.7s;
            z-index: 1;
        }

        .nav__responsive-ul {
            position: absolute;
            right: 0;
            display: block;
            width: 100%;
            height: 37px;
            margin-top: 37px;
            padding-top: 27px;
        }

        .nav__responsive-li {
            padding: 9px;
        }

        .nav__responsive-li i {
            box-sizing: border-box;
            text-align: center;
            margin-right: 5px;
            width: 30px;
            color: $white;
        }

        .nav__responsive-li a {
            color: $white;
            text-decoration: none;
        }

        .nav__responsive-ul:hover > .nav__li-container {
            left: 0;
        }
    }
}
