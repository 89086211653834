* {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
}


main {
    width: 100%;
}

.loader {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-top: 10%;
    width: 100%;
    vertical-align: center;
}

.mt-1 {
    margin-top: 5px;
}

.mt-5 {
    margin-top: 20px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-5 {
    margin-bottom: 20px;
}

.pointer {
    cursor: pointer;
}
