.main {
    text-align: center;

    .main__circle {
        margin-top: 80px;
        border-radius: 50%;
        display: inline-block;
        width: 50%;

        img {
            width: 100%;
            border-radius: 50%;
            display: block;
        }
    }

    .main__div {
        color: $white;
        font-size: 40px;
        text-align: center;
        padding: 10px;
        white-space: nowrap;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .main {
        display: flex;
        flex-direction: row;
        padding: 10%;

        .main__circle {
            width: 30%;
        }

        .main__div {
            margin: auto;
            vertical-align: middle;
            font-size: 50px;
            position: relative;
        }
    }
}

@media (min-width: 1024px) {
    .main {
        display: flex;
        flex-direction: row;
        padding: 10%;
        padding-top: 20vh;
        min-height: 100vh ;

        .main__circle {
            width: 35%;
        }

        .main__div {
            margin: auto;
            vertical-align: middle;
            font-size: 60px;
            position: relative;
        }
    }
}
