.main-slider{
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    width: 80%;
    text-align: center;

    h1{
        font-size: 17px;
        color: white;
        margin-bottom: 15px;
    }

    .project-card{
        width: 100%;
        height: 180px;
        text-align: center;
        img{
            width: 100%;
            height: 75%;
            object-fit: contain;
        }

        span{
            width: 100%;
            color: white;
            font-size: 12px;
        }
    }
}


@media (min-width: 768px) and (max-width: 1024px){
    .main-slider{
        h1{
            font-size: 30px;
            margin-bottom: 25px;
        }
    }
}

@media (min-width: 1024px){
    .main-slider{
        h1{
            font-size: 40px;
            margin-bottom: 35px;
        }
    }
}